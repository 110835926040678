import moment from "moment";

export const log = (...args: unknown[]) => {
  if (import.meta.env.DEV && import.meta.client) {
    const style =
      "background: linear-gradient(30deg, #770971, #fe4317 70%); color: #ffffff; font-family: sans-serif; font-weight: bold; text-align: center; padding: 0.1rem 0.1rem;";
    console.log("%cFK", style, ...args);
  }
};

export const formatEuro = (amount: number) => {
  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
};

export const formatYouTubeVideo = (value: string) => {
  if (!value) return null;
  if (value.includes("watch?v=")) {
    return value.split("watch?v=")[1];
  } else if (value.includes("youtu.be/")) {
    return value.split("youtu.be/")[1].split("?")[0];
  } else {
    return value;
  }
};

export const formatFullName = (first: string, infix: string, last: string) => {
  const name = `${first ?? ""} ${infix ?? ""}  ${last ?? ""}`;
  return name.trim();
};

export const formatIso8601Date = (iso8601: string) => {
  const date = new Date(iso8601);
  return moment(date).format("DD.MM.YYYY");
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertToUrlParams = (
  obj: Record<string, any>,
  prefix: string = "",
): string => {
  const str: string[] = [];
  for (const p in obj) {
    if (Object.hasOwnProperty.call(obj, p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? convertToUrlParams(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
      );
    }
  }
  return str.join("&");
};
